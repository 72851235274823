import { gql } from '@apollo/client';

export default gql`
  query($promoEmailInfo: PromoEmailInfo) {
    submitPromoEmail(promoEmailInfo: $promoEmailInfo) {
      message
      success
    }
  }
`;
